import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { BasicLayoutComponent } from './basic-layout/basic-layout.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MobileLayoutComponent } from './mobile-layout/mobile-layout.component';
import { MobileDashboardLayoutComponent } from './mobile-dashboard-layout/mobile-dashboard-layout.component';
import { HeaderActionBarComponent } from '@shared/modules/layouts/basic-layout/components/header-action-bar/header-action-bar.component';
import { HeaderTitleBarComponent } from '@shared/modules/layouts/basic-layout/components/header-title-bar/header-title-bar.component';
import { NavBarMobileComponent } from '@shared/modules/layouts/components/nav-bar/nav-bar-mobile/nav-bar-mobile.component';
import { LoadStateHandlerService } from '@core/services/load-state-handler.service';
import { FooterActionBarComponent } from './basic-layout/components/footer-action-bar/footer-action-bar.component';
import { HeaderMobileComponent } from '@shared/modules/layouts/components/header/header-mobile/header-mobile.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { PublicLayoutComponent } from './public-layout/public-layout.component';

const BASE_COMPONENTS = [
  BasicLayoutComponent,
  SidebarComponent,
  MobileDashboardLayoutComponent,
  NavBarMobileComponent,
  HeaderActionBarComponent,
  HeaderTitleBarComponent,
  FooterActionBarComponent,
];

@NgModule({
  declarations: [MobileLayoutComponent, PublicLayoutComponent, HeaderMobileComponent, ...BASE_COMPONENTS],
  exports: [...BASE_COMPONENTS],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MenuModule,
    DropdownModule,
    CapturumInputModule,
    CapturumSkeletonModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
  ],
  providers: [LoadStateHandlerService],
})
export class LayoutsModule {}
