import { Component } from '@angular/core';
import { SynchronisationService } from '@core/services/synchronisation.service';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent {
  constructor(private synchronizationService: SynchronisationService) {
    this.synchronizationService.listenForMobileUndoFinish();
  }
}
