@if (!isInteractiveMobileHeaderShown) {
  <div class="header-mobile">
    <div class="logo-image-container pt-2 pb-2">
      <img class="h-100" alt="Marginpar DXP logo" routerLink="/" src="./assets/images/emendis-complete-logo.png" />
    </div>
  </div>
}

@if (isNavMenuShown$ | async) {
  <div class="nav-menu-section d-md-none">
    <app-header-nav-menu></app-header-nav-menu>
  </div>
}
