import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AsideMenuItem } from '@core/enums/aside-menu-item.model';
import { AppRoutes } from '@core/enums/routes.enum';
import { MobileUndoService } from '@core/services/mobile-undo.service';

@Component({
  selector: 'app-mobile-dashboard-layout',
  templateUrl: './mobile-dashboard-layout.component.html',
  styleUrls: ['./mobile-dashboard-layout.component.scss'],
})
export class MobileDashboardLayoutComponent implements OnInit, OnDestroy {
  @Input() public asideMenuItems: AsideMenuItem[];
  @Input() public backNavigationURL: string[] | false = ['/', AppRoutes.mobile, AppRoutes.dashboard];
  @Input() public circleStylePreset: 'primary' | 'secondary' = 'primary';

  constructor(private mobileUndoService: MobileUndoService) {}

  public actionCallback(item: AsideMenuItem): void {
    if (!item.disabled && item.action) {
      item.action();
    }
  }

  public ngOnInit(): void {
    this.mobileUndoService.setUndoVisible(true);
  }

  public ngOnDestroy(): void {
    this.mobileUndoService.setUndoVisible(false);
  }

  public getIconCircleStyleClass(): string {
    return this.circleStylePreset === 'primary' ? 'icon-circle--primary' : 'icon-circle--secondary';
  }
}
