@if (backNavigationURL) {
  <div class="col mb-4">
    <a [routerLink]="backNavigationURL" class="link-navigate-back-page">
      <i class="fa fa-chevron-left"></i>&nbsp;&nbsp;{{ 'button.back-to-overview' | translate }}</a
    >
  </div>
}

<div class="row dashboard__cards g-0">
  @for (menu of asideMenuItems; track menu) {
    <div *ngxPermissionsOnly="menu.permission" class="col-6">
      <div
        class="dashboard__card"
        [routerLink]="menu.disabled ? null : menu.link"
        [ngClass]="{ 'dashboard--disabled': menu.disabled }"
        [attr.data-test]="menu.link"
        (click)="actionCallback(menu)">
        <div [ngClass]="getIconCircleStyleClass()">
          <i [className]="menu?.icon"></i>
        </div>
        <p>{{ menu.label | observablePipe | async }}</p>
      </div>
    </div>
  }
</div>
