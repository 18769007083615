import { Component, Input } from '@angular/core';
import { MobileNavService } from '@core/services/mobile-nav.service';
import { Router } from '@angular/router';
import { MobileNavConfig, MobileSideConfig } from '@core/models/mobile-nav-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { filter, takeUntil } from 'rxjs/operators';
import { MobileAction } from '@core/enums/mobile-action.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { SynchronisationService } from '@core/services/synchronisation.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-nav-bar-mobile',
  templateUrl: './nav-bar-mobile.component.html',
  styleUrls: ['./nav-bar-mobile.component.scss'],
})
export class NavBarMobileComponent extends DestroyBase {
  @Input() public synchronization = true;
  public config: MobileNavConfig;
  public MobileAction: typeof MobileAction = MobileAction;
  public routes: typeof AppRoutes = AppRoutes;
  public appVersion: string;

  constructor(
    private mobileNavService: MobileNavService,
    private synchronisationService: SynchronisationService,
    private router: Router,
  ) {
    super();

    this.mobileNavService.configAction$
      .pipe(
        filter((action) => {
          return action === MobileAction.add;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.config = this.mobileNavService.getConfig(this.router.url);
      });

    this.appVersion = environment.gitVersion;
  }

  public sideClick(action: MobileAction): void {
    this.mobileNavService.configAction.next(action);
  }

  public isEmptyConfig(): boolean {
    return !this.config || (!this.config.leftSide && !this.config.centerSide && !this.config.rightSide);
  }

  public getClass(side: Partial<MobileSideConfig>): string {
    return side.styleClass ? `${side.icon} ${side.styleClass}` : side.icon;
  }

  public synchronize(): void {
    this.synchronisationService.synchronize();
  }
}
