import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { LayoutConfig } from '@shared/services/layout-config.service';
import { DestroyBase } from '@core/base/destroy.class';
import { BehaviorSubject } from 'rxjs';
import { InactivityService } from '@core/services/inactivity.service';
import { ValidatorService } from '@capturum/ui/api';
import { ScreenType } from '@core/enums/screen-type.enum';
import { CommonAnimations } from '@shared/animations/common.animations';

@Component({
  selector: 'app-basic',
  templateUrl: 'basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  animations: [CommonAnimations.leftSidebar],
})
export class BasicLayoutComponent extends DestroyBase implements OnInit {
  public routes: typeof AppRoutes = AppRoutes;
  public layoutConfig$: BehaviorSubject<LayoutConfig> = new BehaviorSubject<LayoutConfig>(null);
  public screenTypes: typeof ScreenType = ScreenType;
  public openedSidebar = false;
  public screenType: ScreenType;

  @ViewChild('pageContentWrapper') public contentWrapper: ElementRef;

  private titleHeight = 0;
  private actionHeight = 0;
  private totalHeight = 0;

  constructor(
    private validatorService: ValidatorService,
    private inactivityService: InactivityService,
    private renderer: Renderer2,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setValidatorsMessages();
    this.inactivityService.checkActivityStatus(); // Only need to check on authenticated pages
  }

  public updateTitleHeight(newHeight: number): void {
    this.titleHeight = newHeight;
    this.updateTopContentOffset();
  }

  public updateActionHeight(newHeight: number): void {
    this.actionHeight = newHeight;
    this.updateTopContentOffset();
  }

  public updateTopContentOffset(): void {
    this.totalHeight = this.titleHeight + this.actionHeight;

    if (!!this.contentWrapper && this.contentWrapper.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.contentWrapper.nativeElement, 'margin-top', `${this.totalHeight + 20}px`);
    }
  }

  public updateBottomContentOffset(newHeight: number): void {
    if (!!this.contentWrapper && this.contentWrapper.hasOwnProperty('nativeElement')) {
      this.renderer.setStyle(this.contentWrapper.nativeElement, 'margin-bottom', `${newHeight + 20}px`);
    }
  }

  public setStatusOfSidebar(sidebarStatus: { isOpened: boolean; screenType: ScreenType }): void {
    this.openedSidebar = sidebarStatus.isOpened;
    this.screenType = sidebarStatus.screenType;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    this.setStatusOfSidebar({ isOpened: false, screenType: this.screenType });
  }

  private setValidatorsMessages(): void {
    const validations: { label: string; module?: string }[] = [
      { label: 'required' },
      { label: 'password' },
      { label: 'email' },
      { label: 'min' },
      { label: 'max' },
      { label: 'pattern' },
      { label: 'at_least_one', module: 'dxp' },
      { label: 'at_least_one_contact', module: 'dxp' },
      { label: 'minLength', module: 'dxp' },
      { label: 'maxLength', module: 'dxp' },
      { label: 'invalid_files', module: 'dxp' },
      { label: 'number_too_low', module: 'dxp' },
      { label: 'number_too_high', module: 'dxp' },
      { label: 'ends_with', module: 'dxp' },
    ];

    const messages = validations.reduce((acc, validatorName) => {
      const translationKey = `validation-errors.${validatorName.label}`;

      acc[validatorName.label] = validatorName.module ? `${validatorName.module}.${translationKey}` : translationKey;

      return acc;
    }, {});

    this.validatorService.setValidationMessages({
      ...messages,
      emailBusy: 'validation-errors.email-in-use',
      fieldMatch: 'user.detail.password-mismatch.message',
    });
  }
}
