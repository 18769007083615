<div class="dashboard-layout">
  <div class="d-flex">
    <!-- ASIDE NAVIGATION MENU -->
    @if (
      openedSidebar &&
      (screenType === screenTypes.SM ||
        screenType === screenTypes.MD ||
        screenType === screenTypes.LG ||
        screenType === screenTypes.XL)
    ) {
      <div class="dashboard-sidebar collapsible-view" [@leftSidebar]="openedSidebar">
        <app-sidebar (click)="setStatusOfSidebar({ isOpened: false, screenType: screenType })"></app-sidebar>
      </div>
    } @else {
      <div class="dashboard-sidebar">
        <app-sidebar></app-sidebar>
      </div>
    }

    <div class="dashboard-page">
      <!-- HEADER -->
      <div class="responsive--header">
        <div class="responsive--header-height">
          <div class="white">
            <div>
              <app-header-title-bar
                [isSidebarOpened]="openedSidebar"
                [layoutConfig]="layoutConfig$ | async"
                (titleHeightChanged)="updateTitleHeight($event)"
                (sidebarOpened)="setStatusOfSidebar($event)">
              </app-header-title-bar>
            </div>
          </div>
          <div>
            <app-header-action-bar (actionHeightChanged)="updateActionHeight($event)"> </app-header-action-bar>
          </div>
        </div>
      </div>

      <!-- MAIN-CONTENT -->
      <div class="page-content" #pageContentWrapper>
        <div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  @if (openedSidebar) {
    <div (click)="setStatusOfSidebar({ isOpened: false, screenType: screenType })" class="backDrop"></div>
  }

  <!-- FOOTER -->
  <app-footer-action-bar #footerWrapper (footerHeightChanged)="updateBottomContentOffset($event)">
  </app-footer-action-bar>
</div>
