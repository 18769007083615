@if (layoutConfig) {
  <nav #titleBar>
    <div class="title-bar">
      <!-- TITLE_RIGHT -->
      <div class="equal-flex-col flex-left">
        @if (customComponents.left?.length > 0) {
          @for (custom of customComponents.left; track custom) {
            <ng-container [ngTemplateOutlet]="custom.templateRef"> </ng-container>
          }
        } @else {
          @if (screenType === screenTypes.XS) {
            @if (!layoutConfig.backUrl) {
              <ng-container [ngTemplateOutlet]="hamburgerMenu"> </ng-container>
            }
            @if (layoutConfig.backUrl) {
              <ng-container [ngTemplateOutlet]="backButtonWithoutText"> </ng-container>
            }
          }
          @if (screenType === screenTypes.SM) {
            <ng-container [ngTemplateOutlet]="collapsibleHamburgerMenu"> </ng-container>
            @if (layoutConfig.backUrl) {
              <ng-container [ngTemplateOutlet]="backButtonWithoutText"> </ng-container>
            }
          }
          @if (screenType === screenTypes.MD || screenType === screenTypes.LG || screenType === screenTypes.XL) {
            <ng-container [ngTemplateOutlet]="collapsibleHamburgerMenu"> </ng-container>
          }
          @if (
            layoutConfig.backUrl &&
            (screenType === screenTypes.MD ||
              screenType === screenTypes.LG ||
              screenType === screenTypes.XL ||
              screenType === screenTypes.XXL)
          ) {
            <ng-container [ngTemplateOutlet]="backButtonWithText"> </ng-container>
          }
        }
      </div>
      <div class="equal-flex-col flex-center">
        @if (customComponents.center?.length > 0) {
          @for (custom of customComponents.center; track custom) {
            <ng-container [ngTemplateOutlet]="custom.templateRef"> </ng-container>
          }
        } @else {
          @if (!layoutConfig.title) {
            <div class="logo-image-container pt-2 pb-2">
              <img
                class="h-100"
                alt="Marginpar DXP logo"
                routerLink="/"
                src="./assets/images/emendis-complete-logo.png" />
              @if (screenType === screenTypes.XS) {
                <div class="app-version">DXP {{ appVersion | subString: '-' }}</div>
              }
            </div>
          }
          @if (layoutConfig.title) {
            <h1 class="layout-title">{{ layoutConfig.title | observablePipe | async }}</h1>
          }
        }
      </div>
      <div class="equal-flex-col flex-right">
        @if (customComponents.right?.length > 0) {
          @for (custom of customComponents.right; track custom) {
            <ng-container [ngTemplateOutlet]="custom.templateRef"> </ng-container>
          }
        } @else {
          @if (screenType === screenTypes.XS) {
            <app-synchronization (syncClicked)="synchronize()"></app-synchronization>
            @for (button of layoutConfig.actionButtons; track button) {
              <!-- Override action button -->
              @if (button.type === buttonTypes.ADD) {
                @if (!button?.isHidden) {
                  <ng-container *ngxPermissionsOnly="button.permissions">
                    <a (click)="layoutConfigService.doAction(button)">
                      <div class="icon">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </div>
                    </a>
                  </ng-container>
                }
              }
            }
            <!-- Show actionMenu last -->
            @if (layoutConfig.actionMenu) {
              <app-action-menu class="action-menu" [actionItems]="layoutConfig.actionMenu"></app-action-menu>
            }
          }
          <!-- Show userprofile on all screens except: XS -->
          @if (
            screenType === screenTypes.SM ||
            screenType === screenTypes.MD ||
            screenType === screenTypes.LG ||
            screenType === screenTypes.XL ||
            screenType === screenTypes.XXL
          ) {
            <div class="d-flex align-items-center" (click)="menu.toggle($event)">
              <div class="user d-inline-block">
                <span class="d-block user-name">{{ user?.name }}</span>
                @if (activeFarm) {
                  <span class="d-block active-farm">{{ activeFarm.name }}</span>
                }
              </div>
              <app-badge-button #badge icon="fas fa-ellipsis-v" [isLightTheme]="true"> </app-badge-button>
              <p-overlayPanel #menu appendTo="body" styleClass="cap-overlay-panel header-title-bar">
                <app-user-menu (atAction)="menu.hide()"></app-user-menu>
              </p-overlayPanel>
            </div>
          }
        }
      </div>
    </div>
    @if (screenType === screenTypes.XS && layoutConfig.tabItems && layoutConfig.tabItems.length > 0) {
      <div class="actionbar--tabs">
        <p-tabView styleClass="header-tabview-mobile w-100 p-0" (onChange)="changeTabIndex($event)">
          @for (tab of layoutConfig.tabItems; track tab; let i = $index) {
            <p-tabPanel [header]="tab.label | observablePipe | async" [selected]="i === 0"> </p-tabPanel>
          }
        </p-tabView>
      </div>
    }
  </nav>
}

<ng-template #hamburgerMenu>
  <a
    class="mobile-primary-action-icon buttonContainer"
    data-test="more-actions"
    [queryParams]="{ returnUrl: router.url }"
    [routerLink]="['/', AppRoutes.admin, AppRoutes.moreActions]">
    <i class="fas fa-bars"></i>
  </a>
</ng-template>

<ng-template #collapsibleHamburgerMenu>
  <a class="mobile-primary-action-icon buttonContainer" (click)="toggleMenu(!isSidebarOpened)">
    <i class="fas fa-bars"></i>
  </a>
</ng-template>

<ng-template #backButtonWithoutText>
  <a class="link-navigate-back-page buttonContainer" (click)="goBack()">
    <span><i class="fa fa-chevron-left"></i></span>
  </a>
</ng-template>

<ng-template #backButtonWithText>
  <a class="link-navigate-back-page" (click)="goBack()">
    <span><i class="fa fa-chevron-left"></i>&nbsp;&nbsp;{{ 'button.back-to-overview' | translate }}</span>
  </a>
</ng-template>
