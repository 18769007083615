import { AfterViewInit, Component, HostBinding, Input } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Direction, VisibilityState } from '@core/enums/ui-general.enum';
import { LayoutUtilsService } from '@shared/services/layout-utils.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: 'header-mobile.html',
  styleUrls: ['header-mobile.scss'],
  animations: [
    trigger('toggle', [
      state(VisibilityState.hidden, style({ opacity: 0, transform: 'translateY(-100%)' })),
      state(VisibilityState.visible, style({ opacity: 1, transform: 'translateY(0)' })),
      transition('* => *', animate('200ms ease-in')),
    ]),
  ],
})
export class HeaderMobileComponent implements AfterViewInit {
  public isVisible = true;
  public isNavMenuShown$: Observable<boolean>;
  public isInteractiveMobileHeaderShown = true;

  @Input() public hasMenuNav = true;

  constructor(private layoutUtilsService: LayoutUtilsService) {
    this.isNavMenuShown$ = layoutUtilsService.isMobileHeaderSubMenuShown$.asObservable();
  }

  @HostBinding('@toggle')
  public get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.visible : VisibilityState.hidden;
  }

  public ngAfterViewInit(): void {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => {
        return window.pageYOffset;
      }),
      pairwise(),
      map(([y1, y2]): Direction => {
        return y2 < y1 ? Direction.up : Direction.down;
      }),
      distinctUntilChanged(),
      share(),
    );

    const goingUp$ = scroll$.pipe(
      filter((direction) => {
        return direction === Direction.up;
      }),
    );

    const goingDown$ = scroll$.pipe(
      filter((direction) => {
        return direction === Direction.down;
      }),
    );

    goingUp$.subscribe(() => {
      return (this.isVisible = true);
    });
    goingDown$.subscribe(() => {
      return (this.isVisible = false);
    });
  }
}
