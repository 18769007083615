<div class="sidebar">
  <div class="sidebar-brand">
    <div class="brand-wrapper" [routerLink]="homePageUrl">
      <img alt="Marginpar DXP logo" src="./assets/images/emendis-complete-logo.png" />

      <h2 class="sidebar-title">
        <b>Marginpar DXP</b>
      </h2>
    </div>
  </div>

  <ul class="sidebar-menus">
    @for (menu of menus; track menu) {
      @if (menu?.permission?.length !== 0) {
        <li
          *ngxPermissionsOnly="menu.permission"
          routerLinkActive="active"
          [attr.data-test]="menu.link"
          [routerLinkActiveOptions]="routerOptions"
          [routerLink]="'/' + menu.link">
          <i [className]="menu.icon"></i>
          <span [innerHTML]="menu.label | observablePipe | async"></span>
        </li>
      }
    }
  </ul>

  <span class="app-version">DXP {{ appVersion | subString: '-' }}</span>
</div>
