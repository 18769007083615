import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DestroyBase } from '@core/base/destroy.class';
import { AppRoutes } from '@core/enums/routes.enum';
import { ScreenType } from '@core/enums/screen-type.enum';
import { ButtonType, LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { Router } from '@angular/router';
import { ResponsiveService } from '@shared/services/responsive.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-footer-action-bar',
  templateUrl: './footer-action-bar.component.html',
  styleUrls: ['./footer-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterActionBarComponent extends DestroyBase implements OnInit, AfterViewChecked {
  public routes: typeof AppRoutes = AppRoutes;
  public buttonTypes: typeof ButtonType = ButtonType;
  public screenType: ScreenType;
  public layoutConfig: LayoutConfig;

  @Output() public footerHeightChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('footerBar') public footerBar: ElementRef;

  private footerHeight = 0;

  constructor(
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private responsiveService: ResponsiveService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public updateHeight(): void {
    const newHeight = this.footerBar ? this.footerBar.nativeElement?.offsetHeight : 0;

    if (this.footerHeight !== newHeight) {
      this.footerHeight = newHeight;
      this.footerHeightChanged.emit(this.footerHeight);

      this.cdr.markForCheck();
    }
  }

  public ngAfterViewChecked(): void {
    this.updateHeight();
  }

  public ngOnInit(): void {
    this.layoutConfigService.layoutConfig$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response.url === this.router.url) {
        this.layoutConfig = response;
        this.updateHeight();

        this.cdr.detectChanges();
      }
    });

    this.responsiveService.screenTypeChanged$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.screenType = response;
      this.updateHeight();
    });

    this.layoutConfig = this.layoutConfigService.getConfig(this.router.url);
  }
}
