import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyBase } from '@core/base/destroy.class';
import { AppRoutes } from '@core/enums/routes.enum';
import { SynchronisationService } from '@core/services/synchronisation.service';
import { ResponsiveService } from '@shared/services/responsive.service';
import { ScreenType } from '@core/enums/screen-type.enum';
import { ButtonType, LayoutConfig, LayoutConfigService, LayoutPosition } from '@shared/services/layout-config.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import User from '@core/models/user.model';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/admin/general/general.selectors';
import { Farm } from '@core/models/farm.model';
import { SetActiveFarm } from '@store/admin/general/general.actions';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AuthService } from '@capturum/auth';
import { RouterUtil } from '@core/utils/router-util';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-header-title-bar',
  templateUrl: './header-title-bar.component.html',
  styleUrls: ['./header-title-bar.component.scss'],
})
export class HeaderTitleBarComponent extends DestroyBase implements OnInit, AfterViewChecked {
  @Input() public layoutConfig: LayoutConfig;
  @Input() public isSidebarOpened: boolean;
  @Output() public titleHeightChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public sidebarOpened: EventEmitter<{ isOpened: boolean; screenType: ScreenType }> = new EventEmitter<{
    isOpened: boolean;
    screenType: ScreenType;
  }>();

  public AppRoutes: typeof AppRoutes = AppRoutes;
  public screenTypes: typeof ScreenType = ScreenType;
  public buttonTypes: typeof ButtonType = ButtonType;
  public screenType: ScreenType;
  public user: User;
  public customComponents: any = [];
  public activeFarm: Farm;
  public appVersion: string;
  @ViewChild('titleBar') public titleBar: ElementRef;
  @ViewChild('menu') public menu: OverlayPanel;
  public activeFarm$: Observable<Farm>;

  private menuHeight = 0;

  constructor(
    public layoutConfigService: LayoutConfigService,
    public router: Router,
    private authService: AuthService,
    private responsiveService: ResponsiveService,
    private synchronisationService: SynchronisationService,
    private store: Store,
  ) {
    super();

    this.user = this.authService.getUser() as User;
    this.appVersion = environment.gitVersion;
    this.activeFarm$ = this.store.select(GeneralSelectors.getActiveFarm);
  }

  public updateHeight(): void {
    const newHeight = this.titleBar ? this.titleBar.nativeElement?.offsetHeight : 0;

    if (this.menuHeight !== newHeight) {
      this.menuHeight = newHeight;
      this.titleHeightChanged.emit(this.menuHeight);
    }
  }

  public ngAfterViewChecked(): void {
    this.updateHeight();
  }

  public ngOnInit(): void {
    this.layoutConfigService.layoutConfig$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      if (response.url === this.router.url) {
        this.layoutConfig = response;

        this.customComponents = { right: [], center: [], left: [] };
        if (this.layoutConfig.customComponents) {
          this.customComponents = {
            right: this.layoutConfig.customComponents.filter((component) => {
              return component.position === LayoutPosition.TITLE_RIGHT;
            }),
            center: this.layoutConfig.customComponents.filter((component) => {
              return component.position === LayoutPosition.TITLE_CENTER;
            }),
            left: this.layoutConfig.customComponents.filter((component) => {
              return component.position === LayoutPosition.TITLE_LEFT;
            }),
          };
        }

        this.updateHeight();
      }

      this.layoutConfig = response;
    });

    this.responsiveService.screenTypeChanged$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.screenType = response;
      this.updateHeight();
    });

    this.getActiveFarm();
  }

  public changeTabIndex($event: any): void {
    this.layoutConfigService.setActiveTab($event);
  }

  public synchronize(): void {
    this.synchronisationService.synchronize();
  }

  public goBack(): void {
    const backUrl = this.layoutConfig.backUrl;

    if (Array.isArray(backUrl)) {
      if (this.layoutConfig.backUrlTab) {
        this.router.navigate(backUrl, { queryParams: { tab: this.layoutConfig.backUrlTab } });
      } else {
        this.router.navigate(backUrl);
      }
    } else if (typeof backUrl === 'string') {
      this.router.navigate(...RouterUtil.keepUrlTree(backUrl));
    }
  }

  public toggleMenu(isOpen: boolean): void {
    this.isSidebarOpened = isOpen;
    this.sidebarOpened.emit({
      isOpened: this.isSidebarOpened,
      screenType: this.screenType,
    });
  }

  private getActiveFarm(): void {
    if (this.user?.activeFarm) {
      this.store.dispatch(new SetActiveFarm(this.user.activeFarm));
    }

    this.activeFarm$.pipe(takeUntil(this.destroy$)).subscribe((activeFarm) => {
      this.activeFarm = activeFarm;

      if (this.menu) {
        this.menu.hide();
      }
    });
  }
}
