@if (
  layoutConfig &&
  layoutConfig.actionButtons &&
  !(layoutConfig.actionButtons | contains: [{ key: 'type', value: buttonTypes.ADD }])
) {
  <div #footerBar class="mobile--bottom-actions flex-wrap">
    @for (button of layoutConfig.actionButtons; track button) {
      @if (!button?.isHidden) {
        <ng-container *ngxPermissionsOnly="button.permissions">
          <cap-button
            appPreventDoubleClick
            (clickEvent)="layoutConfigService.doAction(button)"
            [debounceTime]="button.debounceTime | debounceTimeByType: button.type"
            [attr.data-test]="
              button.testAttrName
                ? button.testAttrName
                : layoutConfig.entity
                  ? (layoutConfig.entity | e2eAttr: button.type)
                  : null
            "
            [icon]="button.icon | observablePipe | async"
            [label]="
              button.label
                ? (button.label | observablePipe | async | translate)
                : layoutConfig.entity
                  ? ('dxp.' + layoutConfig.entity + '.button.' + button.type | translate)
                  : null
            "
            [styleClass]="
              [button.styleClass ? button.styleClass : 'primary', 'btn-block ', 'w-100', 'h-100'] | join: ' '
            ">
          </cap-button>
        </ng-container>
      }
    }
  </div>
}
