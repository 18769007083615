import { Injectable } from '@angular/core';
import { MobileUndoService } from '@core/services/mobile-undo.service';
import { ResetSync, SetProgressStatus, SetSyncErrors } from '@store/mobile/sync/sync.actions';
import { combineLatest, Observable } from 'rxjs';
import { filter, mergeMap, switchMap, take } from 'rxjs/operators';
import { ObservationService } from '@core/api/observation.service';
import { HarvestService } from '@core/api/harvest.service';
import { Store } from '@ngxs/store';
import { ModuleService } from '@core/api/module.service';
import { SyncErrorsService } from '@core/services/sync-errors.service';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { ResetObservation } from '@store/mobile/observation/observation.actions';
import { ConnectivityService } from '@capturum/complete';
import { SyncSelectors } from '@store/mobile/sync/sync.selectors';
import moment from 'moment';
import { DatesFormats } from '@core/configs/date-config';

@Injectable({
  providedIn: 'root',
})
export class SynchronisationService {
  public inProgress$: Observable<boolean>;
  public storedItems$: Observable<number>;

  constructor(
    private mobileUndoService: MobileUndoService,
    private observationService: ObservationService,
    private harvestService: HarvestService,
    private store: Store,
    private syncErrorsService: SyncErrorsService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private connectivityService: ConnectivityService,
    private moduleService: ModuleService,
  ) {
    this.inProgress$ = this.store.select(SyncSelectors.getProgressStatus);
    this.storedItems$ = this.store.select(SyncSelectors.getAmount);
  }

  public listenForMobileUndoFinish(): void {
    this.listenForConnectivity();
    this.mobileUndoService
      .getUndoFinished()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.mobileUndoService.setUndoFinished(false);

        if (navigator.onLine) {
          this.synchronize();
        }
      });
  }

  public listenForConnectivity(): void {
    this.store.dispatch(new SetProgressStatus(false));

    // When the user is back online after being offline it should try and sync.
    // The skip operator is used because isOnline will emit a true value on init.
    // So we want to make sure it does not sync the first time

    this.connectivityService
      .isOnline()
      .pipe(
        filter(Boolean),
        switchMap(() => {
          return combineLatest([this.inProgress$, this.storedItems$, this.mobileUndoService.undoAction$]).pipe(
            take(1),
            filter(([inProgress, storedItems, undoAction]) => {
              return !inProgress && !!storedItems && !undoAction.visible;
            }),
          );
        }),
      )
      .subscribe(() => {
        this.synchronize();
      });
  }

  public synchronize(): void {
    this.store.dispatch(new SetProgressStatus(true));

    combineLatest([this.observationService.getStoredObservations(), this.harvestService.getStoredHarvests()])
      .pipe(
        mergeMap(([observations, harvests]) => {
          const requestPayload = {
            data: {
              observations: observations.map((observation) => {
                return { ...observation, value: observation.value.toString() };
              }),
              harvests: harvests.map((harvest) => {
                harvest.cut_date_at = moment(harvest.cut_date_at).format(DatesFormats.sendFormat);

                return harvest;
              }),
            },
          };

          return this.moduleService.sync(requestPayload);
        }),
        take(1),
        switchMap((response) => {
          return this.syncErrorsService.removeSyncedItems(response.data).pipe(
            switchMap(() => {
              return this.syncErrorsService.parseSyncErrors(response.data);
            }),
          );
        }),
        take(1),
      )
      .subscribe(
        (errors) => {
          let newErrors = errors;

          newErrors = newErrors.filter((error) => {
            return !!error;
          });

          if (newErrors.length) {
            this.store.dispatch([new SetSyncErrors(newErrors), new SetProgressStatus(false)]);
          } else {
            this.notificationService.success(
              this.translateService.instant('synchronisation.title'),
              this.translateService.instant('quotation.data-synced'),
            );

            this.mobileUndoService.reset();
            this.store.dispatch([new ResetSync(), new SetProgressStatus(false), new ResetObservation()]);
          }
        },
        (error) => {
          this.notificationService.error(
            this.translateService.instant('synchronisation.title'),
            this.translateService.instant('toast.error.message'),
          );
          this.store.dispatch(new SetProgressStatus(false));
        },
      );
  }
}
