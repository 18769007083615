<nav>
  <div class="nav--content row">
    @if (config?.leftSide) {
      <div
        (click)="sideClick(MobileAction.leftClick)"
        [routerLink]="config?.leftSide.url"
        class="nav__left col-2"
        [class.disabled]="config?.leftSide.disabled ? true : null">
        @if (!config?.leftSide.disabled) {
          <i [className]="getClass(config?.leftSide)"></i>
        }
        @if (config?.leftSide.disabled) {
          <span class="fa-stack fa-xs">
            <i [className]="getClass(config?.leftSide) + ' fa-stack-1x banned--text'"></i>
            <i class="fas fa-ban fa-stack-2x banned"></i>
          </span>
        }
      </div>
    }

    @if (!config?.centerSide?.logo) {
      <div
        (click)="sideClick(MobileAction.centerClick)"
        class="nav__center col-8"
        [routerLink]="config?.centerSide?.url">
        <div class="center--content" [innerHTML]="config?.centerSide?.title | async"></div>
      </div>
    }

    @if (config?.centerSide?.logo) {
      <div class="nav--brand col-8 flex-center {{ config.centerSide.styleClass }}">
        <div class="brand__wrapper">
          <img
            src="./assets/images/emendis-complete-logo.png"
            [routerLink]="['/', routes.mobile, routes.dashboard]"
            alt="Marginpar DXP logo" />
          <div class="nav__version">DXP {{ appVersion | subString: '-' }}</div>
        </div>
      </div>
    }

    @if (config?.rightSide) {
      <div
        [routerLink]="config?.rightSide?.url"
        [class.disabled]="config?.rightSide.disabled ? true : null"
        class="nav__right col-2"
        (click)="sideClick(MobileAction.rightClick)">
        @if (!config?.rightSide.disabled) {
          <i [className]="getClass(config?.rightSide)"></i>
        }
        @if (config?.rightSide.disabled) {
          <span class="fa-stack fa-xs">
            <i [className]="getClass(config?.rightSide) + ' fa-stack-1x banned--text'"></i>
            <i class="fas fa-ban fa-stack-2x banned"></i>
          </span>
        }
      </div>
    }

    @if (isEmptyConfig()) {
      <div class="nav--brand col-12">
        <div class="brand__wrapper">
          <img
            src="./assets/images/emendis-complete-logo.png"
            [routerLink]="['/', routes.mobile, routes.dashboard]"
            alt="Marginpar DXP logo" />
        </div>
        <div class="nav__version">DXP {{ appVersion | subString: '-' }}</div>
      </div>
    }
  </div>
</nav>

@if (synchronization) {
  <app-synchronization (syncClicked)="synchronize()"></app-synchronization>
}
