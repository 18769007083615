import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DestroyBase } from '@core/base/destroy.class';
import { AppRoutes } from '@core/enums/routes.enum';
import { ResponsiveService } from '@shared/services/responsive.service';
import { ScreenType } from '@core/enums/screen-type.enum';
import { LayoutConfig, LayoutConfigService } from '@shared/services/layout-config.service';
import { takeUntil } from 'rxjs/operators';
import { CrudAction } from '@core/enums/crud-action.enum';

@Component({
  selector: 'app-header-action-bar',
  templateUrl: './header-action-bar.component.html',
  styleUrls: ['./header-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderActionBarComponent extends DestroyBase implements OnInit, AfterViewChecked, AfterContentChecked {
  public AppRoutes: typeof AppRoutes = AppRoutes;
  public CrudAction: typeof CrudAction = CrudAction;
  public screenType: ScreenType;
  public layoutConfig: LayoutConfig;

  @Output() public actionHeightChanged: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('actionBar') public actionBar: ElementRef;

  private actionHeight = 0;

  constructor(
    public layoutConfigService: LayoutConfigService,
    private router: Router,
    private responsiveService: ResponsiveService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.layoutConfigService.layoutConfig$.pipe(takeUntil(this.destroy$)).subscribe((layoutConfig) => {
      // Trigger update when navigation ended and config is available
      if (layoutConfig.url === this.router.url) {
        this.layoutConfig = layoutConfig;
        this.updateHeight();
      }

      this.layoutConfig = layoutConfig;
    });

    this.responsiveService.screenTypeChanged$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.screenType = response;
      this.updateHeight();
    });

    this.layoutConfig = this.layoutConfigService.getConfig(this.router.url);
    this.updateHeight();
  }

  public updateHeight(): void {
    let newHeight = this.actionBar ? this.actionBar.nativeElement?.offsetHeight : 0;

    if (this.layoutConfig && !this.layoutConfig.subtitle && newHeight > 0) {
      newHeight = 0;
    }

    if (this.actionHeight !== newHeight) {
      this.actionHeight = newHeight;
      this.actionHeightChanged.emit(this.actionHeight);
    }

    this.cdr.detectChanges();
  }

  public ngAfterViewChecked(): void {
    this.updateHeight();
  }

  public ngAfterContentChecked(): void {
    // Trigger update when router-outlet has new content (page refresh)
    this.updateHeight();
  }
}
