import { Component, OnInit } from '@angular/core';
import { AsideMenuItem } from '@core/enums/aside-menu-item.model';
import { NavigationService } from '@core/services/navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { LocaleService, UserService } from '@capturum/complete';
import { BaseKey } from '@core/enums/base-key.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { PreviousRouteService } from '@core/services/previous-route.service';
import { combineLatest, Observable } from 'rxjs';
import User from '@core/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import Locale from '@capturum/auth/lib/locale.interface';
import { DestroyBase } from '@core/base/destroy.class';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { environment } from '@environments/environment';
import { MapItem } from '@capturum/ui/api';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends DestroyBase implements OnInit {
  public menus: AsideMenuItem[];
  public currentUser: User;
  public routerOptions = { exact: false };
  public homePageUrl = `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`;
  public readonly appVersion: string;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private dxpIndexableBaseDataService: DxpIndexableBaseDataService,
    private userService: UserService,
    private previousRouteService: PreviousRouteService,
    private translateService: TranslateService,
  ) {
    super();

    this.menus = this.navigationService.populateMenus();
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.previousRouteService.getPreviousUrl();
    this.appVersion = environment.gitVersion;

    // This is done to make sure the sidebar updates on every route change.
    // There have been issues with it not updating after a route change
    this.router.events.subscribe((event) => {
      // This will trigger changeDetection so that the sidebar gets updated
      this.routerOptions = { exact: false };

      if (event instanceof NavigationEnd) {
        // This will update home page url after user will visit profile page
        if (
          this.previousRouteService.getPreviousUrl() === `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`
        ) {
          this.getHomePageUrl();
        }
      }
    });
  }

  public ngOnInit(): void {
    this.getHomePageUrl();
  }

  private getHomePageUrl(): void {
    const homePageList$: Observable<MapItem[]> = this.dxpIndexableBaseDataService.getBaseDataValuesByDataKey(
      BaseKey.homePage,
    );
    const user$: Observable<User> = this.userService.get(this.currentUser?.id, { include: ['homepage', 'locale'] });

    combineLatest([homePageList$, user$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([homePageList, user]) => {
        this.switchLanguage(user.locale);

        const homePageId = user.homepage ? user.homepage.id : null;
        let homePage: MapItem;

        if (homePageList) {
          homePage = homePageList.find((page) => {
            return page.value === homePageId;
          });
        }

        if (homePage) {
          const url = this.getUrl(homePage.key);

          if (url) {
            this.homePageUrl = `/${url}`;
          }
        }
      });
  }

  private switchLanguage(localeItem: Locale): void {
    const languageCode = localeItem ? localeItem.code : this.translateService.getBrowserLang();

    this.translateService.use(languageCode);
    LocaleService.setCurrentLocaleCode(languageCode);
  }

  private getUrl(key: string): string {
    const menuElement = this.navigationService.menus.find((menu) => {
      return menu.key_index === key;
    });

    if (menuElement) {
      return menuElement.link;
    }
  }
}
